import React from 'react';
import { Layout, Seo } from 'components/common';
import { Intro, Skills, Contact, Activity } from 'components/sections';

const HomePage = ({ pageContext }) => {
  return (
    <Layout context={pageContext}>
      <Seo />
      <Intro
        title="Hi There!"
        subtitle="I’m Griffen and I’m a full-stack engineer with a passion for
              interactive media, 3D printing, and drones!"
        button={{
          href: '#contact',
          text: 'Lets Talk!',
        }}
      />
      <Activity />
      <Skills />
      <Contact />
    </Layout>
  );
};
export default HomePage;
